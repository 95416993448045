<template>
    <div class="loading-container" v-if="visible">
        <div class="loading">
            <div class="img">
                <img src="https://api.mengjingloulan.com/storage/wechat/common/loading2.gif"/>
            </div>
            <div class="text">{{finalText}}</div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'rsck-loading',
    props: {
      text: {
        type: String,
        default: '加载中'
      }
    },
    data () {
      return {
        visible: false,
        count: 6,
        finalText: this.$props.text,
        timeId: 0
      }
    },
    methods: {
      show () {
        this.visible = true
        this.runTimer(0)
      },
      hide () {
        this.visible = false
        clearTimeout(this.timeId)
      },
      runTimer (time) {
        if (time > this.count) {
          time = 0
          this.finalText = this.$props.text
        } else {
          this.finalText += '.'
          time++
        }

        this.timeId = setTimeout(() => {
          this.runTimer(time)
        },1000)
      }
    }
  }
</script>

<style scoped lang="scss">
    .loading-container {
        width: 100vw;
        height: 100vh;
        background: rgba(255, 255, 255, 0.3);
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;

        .loading {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .img {
                width: 100%;
                display: flex;
                justify-content: center;

                img {
                    width: 50px;
                    height: 50px;
                }
            }

            .text {
                width: 100%;
                display: flex;
                justify-content: center;
                color: #333;
                font-size: 12px;
                height: 15px;
            }
        }
    }
</style>